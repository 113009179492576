import styled from "styled-components";
import { isMobile } from "react-device-detect";

export const SiteContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Container = styled.div`
  height: ${isMobile ? "100%" : "100vh"};
`;
