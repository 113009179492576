import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  body {
    direction: rtl;
    background: white;  
    font-family: Sunday;
    overflow: hidden;
  }
`;
