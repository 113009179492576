import React, { useState, useEffect, lazy, Suspense } from "react";
import { isMobile } from "react-device-detect";
import { ErrorBoundary } from "react-error-boundary";
import GlobalStyles from "./global.styles";
import { Container, SiteContainer } from "./app.styles";
import "./app.css";
import { useLoadScript } from "@react-google-maps/api";
function ErrorFallback({ error, componentStack, resetErrorBoundary }) {
  return <div role="alert"></div>;
}

const MainScreen = lazy(() => import("../MainScreen/MainScreen"));
const HomeScreen = lazy(() => import("../HomeScreen/HomeScreen"));
const WelcomeCard = lazy(() => import("../WelcomeCard/WelcomeCard"));
const Site = lazy(() => import("../MainScreen/Site/Site"));

const renderLoader = () => <p>Loading</p>;

const App = () => {
  const { isLoaded } = useLoadScript({ googleMapsApiKey: "AIzaSyDGRAHGfiPBenIaZJ-CL9-SXuk3dbkudyg" });
  const [showHomeScreen, setShowHomeScreen] = useState(false);
  const [showWelcomeScreen, setShowWelcomeScreen] = useState(false);
  const [showMainScreen, setShowMainScreen] = useState(false);
  const [isUserAlreadySawMainScreen, setIsUserAlreadySawMainScreen] = useState(false);
  const isAppShare = window.location.pathname.includes("appShare");

  useEffect(() => {
    setTimeout(function () {
      let viewheight = window.innerHeight;
      let viewwidth = window.innerWidth;
      let viewport = document.querySelector("meta[name=viewport]");
      viewport.setAttribute("content", "height=" + viewheight + "px, width=" + viewwidth + "px, initial-scale=1.0");
    }, 300);

    (async () => {
      const isSaw = window.localStorage.getItem("sawAlreadyWelcomeScreen");
      setIsUserAlreadySawMainScreen(isSaw);
      setShowHomeScreen(true);
      if (isSaw) {
        await new Promise((r) => setTimeout(r, 5150));
      } else {
        await new Promise((r) => setTimeout(r, 2200));
      }
      if (isSaw) {
        setShowHomeScreen(false);
        setShowMainScreen(true);
      } else {
        if (isMobile) {
          await new Promise((r) => setTimeout(r, 2900));
          setShowHomeScreen(false);
        }
        setShowWelcomeScreen(true);
      }
    })();
  }, []);

  const continuteToMainScreen = async () => {
    window.gtag("event", "click", {
      category: "Welcome Card",
      action: "continute_to_site_clicked",
    });

    window.localStorage.setItem("sawAlreadyWelcomeScreen", true);
    setShowHomeScreen(false);
    setShowWelcomeScreen(false);
    setShowMainScreen(true);
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      {/* // <Suspense fallback={renderLoader()}> */}
      <>
        <GlobalStyles />
        {window.location.pathname.includes("showPlace") ? (
          <SiteContainer>
            <Site isDeepLink={true} />
          </SiteContainer>
        ) : (
          <Container>
            {showHomeScreen && <HomeScreen isUserAlreadySawMainScreen={isUserAlreadySawMainScreen} />}
            {showWelcomeScreen && <WelcomeCard continute={continuteToMainScreen} />}
            {showMainScreen && <MainScreen isAppShare={isAppShare} />}
          </Container>
        )}
        {/* </Suspense> */}
      </>
      //{" "}
    </ErrorBoundary>
  );
};

export default App;
